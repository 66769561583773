import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { prodcontext } from './context/Maincontext';
import '../css/fonts.css';
import '../css/main.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/singleitem.css';
import CryptoJS from "crypto-js";
import tealiumCollect from 'tealium-collect';
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

const RedeemSuccess = () => {
    const navigate = useNavigate();   
    const {getProductbyId, isLoadingbyId, redeemProduct, profile_rygplustype} = prodcontext();
    const {token} = useParams();
    const {id} = useParams();
    const queryParameters = new URLSearchParams(window.location.search);
    const msisdn = queryParameters.get("msisdn");
    const identifier = queryParameters.get("identifier");
    const fromPage = queryParameters.get("pageType");
    const action = queryParameters.get("action");

    var Tealium = require('tealium');
        var tealiumCollect = require('tealium-collect');
        var config = {};		
        // var tealium = Tealium(config);
        var tealium = Tealium();
        tealium.addModule(tealiumCollect);
        // Code for encryption -->
		// Keys for AES encryption-->
        var INITIALIZATION_VECTOR = "710d805acf0d25086d9181a03e773484"; 
        var KEY = "e5a885172b758539489a077fe6f8df60";
        var clave = CryptoJS.enc.Hex.parse(KEY); 
        var vector  = CryptoJS.enc.Hex.parse(INITIALIZATION_VECTOR); 


    useEffect(() => {
	window.scroll(0, 0);
	trackEventClick(redeemProduct[0].id,redeemProduct[0].name,redeemProduct[1].phoneNumber,redeemProduct[2].doc_id, profile_rygplustype,"loQuiero:OK",redeemProduct[0].prizeType);
	
	// Temporizador para mostrar elementos después de 5 segundos
	const timer = setTimeout(() => {
	const elements = document.querySelectorAll('.hidden');
	elements.forEach(el => el.classList.remove('hidden'));
	}, 9500);
	
	// Limpieza del temporizador
	return () => clearTimeout(timer);
	}, []);     
    
    if (isLoadingbyId) {
        return <div>Loading...</div>;
      }

    function tohome(){
        trackEventClickbtn(redeemProduct[0].name,redeemProduct[1].phoneNumber,redeemProduct[2].doc_id, profile_rygplustype,"clickBoton","volver",redeemProduct[0].prizeType);
        navigate("/rechargeonline?identifier="+identifier);
    }
    function toexternal(url){
        trackEventClickbtn(redeemProduct[0].name, redeemProduct[1].phoneNumber,redeemProduct[2].doc_id, profile_rygplustype,"clickBoton","elegirPremio",redeemProduct[0].prizeType);
        window.location.href = url;
    }

    // tealium integration part

                
        function enc1 (data) {           
            var encrypted = CryptoJS.AES.encrypt(data, clave, {iv: vector}); 
            return encrypted.toString().trim(); 
        }				

        var url = new URL(window.location.href).toString();
        var platform;
    
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            platform = 'movilizado';
        } else {
            platform = 'web';
        }
                
        var pageReferrer = document.referrer;

        function trackEventClick(offerid,offername,msisdn_phone, documentId, profile_rygplustype, action,prizeType) {
            var phoneNumber = msisdn_phone;
            var documentId = documentId;
            var profile_type;
            // if(profile_rygplustype=="no_online"){
            //   profile_type="premioOffline";
            // }
            // else{
            //   profile_type="premioOnline";
            // }
            function camelCase(str) {
              return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                  return index == 0 ? word.toLowerCase() : word.toUpperCase();
              }).replace(/\s+/g, '');
          }
                var offerNamecc= camelCase(offername);
                var eventCategory;
                var pageName;
                var pageSubCategory;
            if(fromPage == "rootPage"){
                pageName=platform+":orange:recargaGana:seleccionarPremio";
                eventCategory="recargaGana:seleccionarPremio";
                pageSubCategory="seleccionarPremio";
                

            }
            else if(fromPage == "detailsPage"){
                pageName=platform+":orange:recargaGana:seleccionarPremio:detallePremio:"+offerNamecc;
                eventCategory="recargaGana:seleccionarPremio:detallePremio";
                pageSubCategory="seleccionarPremio:detallePremio:"+offerNamecc;
            }
            var pageEnvironment =`${process.env.REACT_APP_PAGEENV}`;
            var utag_data={
              "platform"			:	platform,
              "brand"				:	"orange",
              "pageName"			:	pageName,
              "pageURL"			:	url,
              "pageReferrer"		:	pageReferrer,
              "pageCategory"		:	"recargaGana",
              "pageSubCategory"	:	pageSubCategory,
              "pageType"			:	"seccion",
              "pageError"			:	"",
              "pageEnvironment"	:	pageEnvironment,
              "pageLanguage"		:	"es",
              "journeyName"		:	"recargaGana:seleccionPremio:" + prizeType,
              "customerLine"		:	phoneNumber, 
              "customerDocument"	:	documentId,
              "eventCategory"		:	eventCategory,
              "eventAction"		:	action,
              "eventLabel"		:	offerNamecc};
  
            //   console.log(utag_data);
              window.utag.track("link", utag_data);

              var utag_data2={
                "platform"			:	platform,
                "brand"				:	"orange",
                "pageName"			:	platform+":orange:recargaGana:seleccionarPremio:confirmacion:"+offerNamecc,
                "pageURL"			:	url,
                "pageReferrer"		:	pageReferrer,
                "pageCategory"		:	"recargaGana",
                "pageSubCategory"	:	"seleccionarPremio:confirmacion:"+offerNamecc,
                "pageType"			:	"seccion",
                "pageError"			:	"",
                "pageEnvironment"	:	pageEnvironment,
                "pageLanguage"		:	"es",
                "journeyName"		:	"recargaGana:seleccionPremio:" + prizeType,
                "customerLine"		:	phoneNumber,
                "customerDocument"	:	documentId,
                "eventCategory"		:	"",
                "eventAction"		:	"",
                "eventLabel"		:	""};
              // tealium.track("view", utag_data);
            //   console.log(utag_data2);
              window.utag.track("view", utag_data2);
              
            }


            function trackEventClickbtn(offername, msisdn_phone, documentId, profile_rygplustype, action, btn_type,prizeType) {
                var phoneNumber = msisdn_phone;
                var documentId = documentId;
                var profile_type;
                // if(profile_rygplustype=="no_online"){
                //   profile_type="premioOffline";
                // }
                // else{
                //   profile_type="premioOnline";
                // }
                function camelCase(str) {
                  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                      return index == 0 ? word.toLowerCase() : word.toUpperCase();
                  }).replace(/\s+/g, '');
              }
              var offerNamecc= camelCase(offername);
              var pageEnvironment =`${process.env.REACT_APP_PAGEENV}`;
                var utag_data={
                  "platform"			:	platform,
                  "brand"				:	"orange",
                  "pageName"			:	platform+":orange:recargaGana:seleccionarPremio:confirmacion:"+offerNamecc,
                  "pageURL"			:	url,
                  "pageReferrer"		:	pageReferrer,
                  "pageCategory"		:	"recargaGana",
                  "pageSubCategory"	:	"seleccionarPremio:confirmacion:"+offerNamecc,
                  "pageType"			:	"seccion",
                  "pageError"			:	"",
                  "pageEnvironment"	:	pageEnvironment,
                  "pageLanguage"		:	"es",
                  "journeyName"		:	"recargaGana:seleccionPremio:" + prizeType,
                  "customerLine"		:	phoneNumber, 
                  "customerDocument"	:	documentId,
                  "eventCategory"		:	"recargaGana:seleccionarPremio:confirmacion",
                  "eventAction"		:	action,
                  "eventLabel"		:	btn_type};
      
                //   console.log(utag_data);
                  window.utag.track("link", utag_data);
                }
    
    var redeemProduct_det=redeemProduct[0];
    console.log(redeemProduct_det);
    return (
        <>
            <br/>{redeemProduct_det != null ?
            <div className='container'>            
                <div className="col-md-12 row">
                <Breadcrumbs aria-label="breadcrumb">
                    <NavLink to={`/?identifier=${identifier}`}> Home </NavLink>
                    <NavLink to={`/?identifier=${identifier}`}> Recarga & Gana </NavLink> 
                    </Breadcrumbs>
                    <div className="col-md-8"><br/>
                        <div className='item_img'>
                        <img className={action === "wheel" ? "img_prop_wheel" : "img_prop"} src={redeemProduct_det['url']} alt={redeemProduct_det.company}/>
                        </div><br/>

                        <div className={action === "wheel" ? "item_heading hidden" : "item_heading"}>
                        <h2 className="subtitle1"><p className='cardText'>{redeemProduct_det['title']}</p></h2>  
                        </div><br/>

                        <div className={action === "wheel" ? "item_description hidden" : "item_description"}>
                        <p className="smallfont">                        
                        {redeemProduct_det['text'].split('|').map((line, index) => (
							<React.Fragment key={index}>
							{line}
							<br /><br />
							</React.Fragment>
						))}
                        </p>
                        </div>
                    </div>
                    <div className={action === "wheel" ? "col-md-4 hidden boton" : "col-md-4"}><br/>
                        <div className='item_btn_redeem_div btn_div'><center>
                        {redeemProduct_det['button']== null ?<button className='item_btn_redeem body_font1' onClick={() => tohome ()}>Volver</button>:<button className='item_btn_redeem body_font1' onClick={() => toexternal(redeemProduct_det['button'])}>Elige tu experiencia</button>}</center>
                        </div>
                    </div>
                </div>            
            </div>
            :<div>Loading...</div>}
        </>
    )
}

export default RedeemSuccess
