const productreducer = (state, action) => {


    switch(action.type){
        case "SET_LOADING" :
            return{
                ...state,
                isLoading:true,
            };
            
        case "API_ERROR" :
            return{
                ...state,
                isLoading:false,
                isError:true,
            };

        case "MY_API_DATA" :
            return{
                ...state,
                isLoading: false,
                isError: false,
                // products: action.payload
                products: action.payload,
                token_date: action.payload.sent_date
            };
        case "MY_API_DATAPROFILE" :
        return{
            ...state,
            isLoading: false,
            isError: false,
            // products: action.payload
            profile_rygplustype: action.payload,
        };

        case "SET_LOADING_HISTORY" :
            return{
                ...state,
                isLoadingHistory:true,
            };
            
        case "API_ERROR_HISTORY" :
            return{
                ...state,
                isLoadingHistory:false,
                isErrorHistory:true,
            };

        case "MY_API_DATA_HISTORY" :
            return{
                ...state,
                isLoadingHistory: false,
                isErrorHistory: false,
                productsHistory: action.payload
            };

        case "SET_LOADING_BYID" :
            return{
                ...state,
                isLoadingbyId:true,
            };
            
        case "API_ERROR_BYID" :
            return{
                ...state,
                isLoadingbyId:false,
                isErrorbyId:true,
            };
            
        case "MY_API_DATA_BYID" :
            return{
                ...state,
                isLoadingbyId: false,
                isErrorbyId: false,
                productbyId: action.payload
            };

        case "MY_API_DATA_REEDEMBYID" :
            return{
                ...state,
                // isLoadingbyId: false,
                // isErrorbyId: false,
                redeemProduct: action.payload
            };
        // case "SET_LOADING_MSISDN" :
        //     return{
        //         ...state,
        //         isLoadingMsisdn:true,
        //         isLoading:true,
        // };

        case "MSISDN" :
            return{ 
                // isLoading:true,
                // isLoadingMsisdn:false,               
                msisdn_phone: action.payload['phoneNumber'],
                documentId: action.payload['documentId']
            };   
            
        case "DocumentId" :
            return{ 
                // isLoading:true,
                // isLoadingMsisdn:false,               
                documentId: action.payload
            };

        case "SET_LOADING_OFFLINEDATA" :
            return{
                ...state,
                isLoadingsofflinedata:true,
            };
        
        case "FETCHOFFLINEDATA" :
            return{
                ...state,
                isLoadingsofflinedata: false,
                productsofflinedata: action.payload,
            };
        case "SET_LOADING_REEDEMBYID":
        return {
            ...state,
            isLoadingbyId: false,
        }
        default:
            return{
                    ...state
            };
        
    }

    
}

export default productreducer
