import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { prodcontext } from './context/Maincontext';
import '../css/fonts.css';
import '../css/main.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/singleitem.css';
import CryptoJS from "crypto-js";
import { API_BASENAME } from './Constant';
import { NavLink } from 'react-router-dom/dist';


const NoActiveOffer = () => {
    const navigate = useNavigate();   
    const {getProducts, getProductbyId, isLoadingbyId, redeemProduct, msisdn_phone, documentId, profile_rygplustype} = prodcontext();
    const {token} = useParams();
    const {id} = useParams();
    const queryParameters = new URLSearchParams(window.location.search);
    const msisdn = queryParameters.get("msisdn");
    const identifier = queryParameters.get("identifier");
    const x = queryParameters.get("x");

    // Code for encryption -->
		// Keys for AES encryption-->
        var Tealium = require('tealium');
        var tealiumCollect = require('tealium-collect');
        var config = {};		
        // var tealium = Tealium(config);
        var tealium = Tealium();
        tealium.addModule(tealiumCollect);
        var INITIALIZATION_VECTOR = "710d805acf0d25086d9181a03e773484"; 
        var KEY = "e5a885172b758539489a077fe6f8df60";
        var clave = CryptoJS.enc.Hex.parse(KEY); 
        var vector  = CryptoJS.enc.Hex.parse(INITIALIZATION_VECTOR); 
        const [msisdnMain, setmsisdnMain] = useState();
        const [documentIdMain, setdocumentIdMain] = useState();
        
    useEffect (() =>{
        // getProductbyId(token,id);
        // const API = "https://stg-rygplus.lumata.com/server_restapi/getUserData";
        // const API = API_BASENAME+"getUserData";
        //  getProducts(API, identifier);
        (async () => {
            const API = API_BASENAME + "getUserDataPlus";
            var resp = await getProducts(API, identifier);
            if (resp) {
                setmsisdnMain(resp['msisdn']);
                setdocumentIdMain(resp['documentId']);
            }
        })();
    }, []);

    function tohome(){
        // navigate("/rechargeonline?identifier="+identifier);
        // window.location.href = url;
    }
    function toexternal(url){
        trackEventClick(msisdnMain, documentIdMain,"clickBoton");
        window.location.href = url;
    }

     // tealium integration part


                
        // function enc1 (data) {           
        //     var encrypted = CryptoJS.AES.encrypt(data, clave, {iv: vector}); 
        //     return encrypted.toString().trim(); 
        // }				

        var url = new URL(window.location.href).toString();
        var platform;
    
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            platform = 'movilizado';
        } else {
            platform = 'web';
        }
                
        var pageReferrer = document.referrer;

        function trackEventClick(msisdn_phone, documentId, action) {
            var phoneNumber = msisdn_phone;
            var documentId = documentId;
            var profile_type;
            // if(profile_rygplustype=="no_online"){
            //   profile_type="premioOffline";
            // }
            // else{
            //   profile_type="premioOnline";
            // }
            var pageEnvironment =`${process.env.REACT_APP_PAGEENV}`;
            var utag_data={
              "platform"			:	platform,
              "brand"				:	"orange",
              "pageName"			:	platform+":orange:recargaGana:seleccionarPremio",
              "pageURL"			:	url,
              "pageReferrer"		:	pageReferrer,
              "pageCategory"		:	"recargaGana",
              "pageSubCategory"	:	"seleccionarPremio",
              "pageType"			:	"seccion",
              "pageError"			:	"",
              "pageEnvironment"	:	pageEnvironment,
              "pageLanguage"		:	"es",
              "journeyName"		:	"",
              "customerLine"		:	phoneNumber, 
              "customerDocument"	:	documentId,
              "eventCategory"		:	"recargaGana:seleccionarPremio",
              "eventAction"		:	action,
              "eventLabel"		:	"vamos"};
  
            //   console.log(utag_data);
              window.utag.track("link", utag_data);
            }

            function trackEventClick_History(msisdn_phone,documentId) {
                var phoneNumber = msisdn_phone;
                var documentId = documentId;
                var pageEnvironment =`${process.env.REACT_APP_PAGEENV}`;
                var utag_data={
                  "platform"			    :	platform,
                  "brand"				      :	"orange",
                  "pageName"			    :	platform+":orange:recargaGana:seleccionarPremio",
                  "pageURL"			      :	url,
                  "pageReferrer"		  :	pageReferrer,
                  "pageCategory"		  :	"recargaGana",
                  "pageSubCategory"	  :	"seleccionarPremio",
                  "pageType"			    :	"seccion",
                  "pageError"			    :	"",
                  "pageEnvironment"	  :	pageEnvironment,
                  "pageLanguage"		  :	"es",
                  "journeyName"		    :	"",
                  "customerLine"		  :	phoneNumber, 
                  "customerDocument"	:	documentId,
                  "eventCategory"		  :	"recargaGana:seleccionarPremio",
                  "eventAction"		    :	"acceso",
                  "eventLabel"		    :	"historialPremios"};
      
                  // console.log(utag_data);
                  window.utag.track("link", utag_data);
              }

    return (
        <>
            <br/>
            <div className='container'>            
                <div className="col-md-12 row">
                    <div className="col-md-8">

                        <div className='subtitle2'>Recarga & Gana</div> <br/>
                        <div className='item_img'>
                        <img className="img_prop" src="./image/no-offer.png" alt="no offer"/>
                        </div><br/>

                        <div className='item_heading'>
                        <h2 className="subtitle1"><p className='cardText'>¡Upps!</p></h2>  
                        </div>                      
											                        
                        {x === '0' ? (
						<div className='item_description'>
	        				<p className="smallfont">Actualmente no hay premios para tí,</p>
	                        <p className="smallfont">¿Por qué no haces una recarga y descubres qué te ha tocado?</p>
                        </div>
      					) : (
						<div className='item_description'>
	        				<p className="smallfont">Recuerda que para participar en el Calendario de Adviento tienes que haber recargado la cuota de tu tarifa GO. Recarga ya y ¡¡podrás disfrutar de un premio cada día!!</p>
	                        <p className="smallfont">Promoción para tarifas Go prepago, Navega y Llama. Máximo una participación por día.  Premios disponibles a partir del día siguiente de la recarga.</p>
                        </div>
      					)}                  
                                                
                    </div>
                    <div className="col-md-4 ">
                        <div className='item_btn_redeem_div btn_div'>
                            <center>
                        {/* {redeemProduct_det['button']== null ?<button className='item_btn_redeem body_font1' onClick={() => tohome ()}>¡Vamos!</button>:<button className='item_btn_redeem body_font1' onClick={() => toexternal(redeemProduct_det['button'])}>¡Vamos!</button>} */}
                        <button className='item_btn_redeem body_font1' onClick={() => toexternal("https://areaprivada.orange.es/soycliente/recarga-tarjeta")}>¡Recarga aquí!</button>
                        </center>
                        </div><br/><br/>
                    </div>
                    <div className="col-md-8 ">
                    <div>
                        
                        <NavLink to={`/awardhistory?identifier=${identifier}`} onClick={()=> trackEventClick_History(msisdnMain,documentIdMain)}>
                        {/* <NavLink to={`/awardhistory?identifier=${identifier}`} onClick={()=> window.scroll(0, 0)}> */}
                        <img className="product--image--history" src="./image/banner-web-link.webp" alt="historypage" />
                        </NavLink><br/><br/>

                </div>
                </div>
                </div><br/>            
            </div>
            
        </>
    )
}

export default NoActiveOffer
