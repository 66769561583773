import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { prodcontext } from './context/Maincontext';
import '../css/fonts.css';
import '../css/main.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/singleitem.css';
import CryptoJS from "crypto-js";
import { API_BASENAME } from './Constant';


const ComingSoon = () => {
    const navigate = useNavigate();   
    const {getProducts, getProductbyId, isLoadingbyId, redeemProduct, msisdn_phone, documentId, profile_rygplustype} = prodcontext();
    const {token} = useParams();
    const {id} = useParams();
    const queryParameters = new URLSearchParams(window.location.search);
    const msisdn = queryParameters.get("msisdn");
    const identifier = queryParameters.get("identifier");

        
    useEffect (() =>{
        // getProductbyId(token,id);
        // const API = "https://stg-rygplus.lumata.com/server_restapi/getUserData";
        // const API = API_BASENAME+"getUserData";
        //  getProducts(API, identifier);
    },[]); 

    

        

    return (
        <>
            <br/>
            <div className='container'>            
                <div className="col-md-12 row">
                    <div className="col-md-8">

                        <div className='allheads subtitle2'>Recarga & Gana</div> <br/>
                        <div className='item_img'>
                        <img className="img_prop" src="./image/cortinillas-v2.webp" alt="comingsoon"/>
                        </div><br/>

                        {/* <div className='item_heading'>
                        <h2 className="subtitle1"><p className='cardText'>¡Upps!</p></h2>  
                        </div><br/>

                        <div className='item_description '>
                        <p className="smallfont">Actualmente no hay premios para tí,</p>
                        <p className="smallfont">¿Por qué no haces una recarga y descubres qué te ha tocado?</p>
                        </div> */}
                    </div>
                    {/* <div className="col-md-4 ">
                        <div className='item_btn_redeem_div btn_div'>
                            <center>
                        
                        <button className='item_btn_redeem body_font1' onClick={() => toexternal("https://areaprivada.orange.es/soycliente/recarga-tarjeta")}>¡Vamos!</button>
                        </center>
                        </div>
                    </div> */}
                </div><br/>            
            </div>
            
        </>
    )
}

export default ComingSoon
