import React, { createContext, useContext, useReducer, useState } from "react";
import axios from "axios";
import reducer from "../reducer/productreducer";
import { useNavigate } from "react-router-dom";
import { API_BASENAME } from "../Constant";

const AppContext = createContext();

var product_offers;
const initialState ={
    isLoadings: false,
    isError: false,
    products: [],
    profile_rygplustype:'',
    token_date:'',
    isLoadingbyId: true,
    isErrorbyId: false,
    productbyId: {},
    isLoadingHistory: true,
    isErrorHistory: false,
    productsHistory: [],
    redeemProduct:[],
    msisdn_phone:'',
    documentId:'',
    isLoadingMsisdn:false,
    isLoadingsofflinedata: false,
    productsofflinedata: [],
    x: '',
    firstTime: true,
    setFirstTime: false
};

function AppProvider({ children }) {

    const [firstTime, setFirstTime] = useState(true);
    const [state, dispatch] = useReducer(reducer, initialState);
    const navigate = useNavigate();
    var msisdn_phn;    


    const getProducts = async (newurl, identifier) => {
        
        var msisdn;
        var documentId_data;
        // dispatch({type : "SET_LOADING_MSISDN"})
        var identifier_det;
        dispatch({type : "SET_LOADING"})
        try{

            // offlin part

            var data_offline;
                await fetch('./offline_data/offlineData.json').then(response => {
                    return response.json();
                  }).then(data => {  
                  
                  data_offline=data;
                  
                }).catch((e) => {
                //   console.log(e.message);
                });
                        
            dispatch({type: "FETCHOFFLINEDATA", payload: data_offline})

            
        const res = await axios.post(newurl,{"identifier":identifier,"status": "multiple"});   
        // console.log(res); 
        if(res["data"].code == 8){
            navigate("/comingsoon?identifier="+identifier);
        }else{		
			// const products1 = await res.data['tokens'];
			// var product_nooffer = [];
			// for(var i=0; i<products1.length; i++){
	        //     if(products1[i].status === "offers_allocated"){
	        //         product_nooffer.push(products1[i]) ;
	        //     }                
	        // }
			
	        // if(product_nooffer.length == 0){
	        //     navigate("/nooffer?identifier="+identifier);
	        // }
	        // else{
	        //     navigate("/?identifier="+identifier);
	        // } 
			
			const prof =  await res.data['profile'];
			const x =  prof["x"];
			const v = prof["v"];
									
			if (x != 0 && firstTime) {
				setFirstTime(false);
				navigate("/christmas?identifier="+identifier+"&x="+x);							
			}else if (v != 0 && firstTime) {
				setFirstTime(false);
				navigate("/valentine?identifier="+identifier+"&v="+v);
			}else if (res.data['tokens'].length == 0){
                navigate("/nooffer?identifier="+identifier+"&x="+x);
            
            }else{
	            const availableOffer = await res.data['tokens'];
            	var offerList = [];
            	var active = false;
            	for(var i=0; i<availableOffer.length; i++){
                	if(availableOffer[i].status === "offers_allocated"){
                    	active=true;
                	}                
            	}
            	if(active == false){
                	navigate("/noactiveoffer?identifier="+identifier+"&x="+x);
            	} 
            	else{
                	navigate("/?identifier="+identifier);
            	}
	        }
    	}        
        const products = await res.data['tokens'];    
        const profile =  await res.data['profile'];
        identifier_det = profile;  
        const profile_rygplustype =  profile["rygplustype"];
        //product_offers=products;
        var product_token_code_det = [];
        for(var i=0; i<products.length; i++){
            if(products[i].status === "offers_allocated"){
                product_token_code_det.push(products[i]) ;
            }                
        }        
        
        // localStorage.setItem("offer_details",JSON.stringify(product_token_code_det));               
        dispatch({type: "MY_API_DATA", payload: product_token_code_det})
        dispatch({type: "MY_API_DATAPROFILE", payload: profile_rygplustype})
        }catch(error){
            dispatch({type: "API_ERROR"})
        }

        return identifier_det;
        };

        const getProductshistory = async (newurl, identifier) => {
           
            dispatch({type : "SET_LOADING_HISTORY"})
            try{
                var msisdn_data;
                var identifier_det; 
            const res = await axios.post(newurl,{"identifier":identifier,"status": "multiple"}); 
            identifier_det = res.data['profile'];            
            const products = await res.data['tokens'];  
            var product_token_code_detail = [];
            var product_offers_detail = [];
            for(var i=0; i<products.length; i++){
                if(products[i].status === "consumed"){
                    product_token_code_detail.push(products[i]) ;
                }                
            }

            

            for(var j=0; j<product_token_code_detail.length; j++){
               
                for(var k=0;k<product_token_code_detail[j]["offers"].length;k++){
                   
                    if(product_token_code_detail[j]["offers"][k].status === "delivered"){
                        
                        //product_token_code_detail[j]["offers"][k].push(product_token_code_detail[j]["offers"][k].consumed_date);
                        product_token_code_detail[j]["offers"][k].consumed_date=product_token_code_detail[j].consumed_date;
                        product_offers_detail.push(product_token_code_detail[j]["offers"][k]) ;
                    }  
                }               
            } 
            
                             
            dispatch({type: "MY_API_DATA_HISTORY", payload: product_offers_detail})
            }catch(error){
                dispatch({type: "API_ERROR_HISTORY"})
            } 
            return identifier_det;
            };

    //Product By Id..

    // const getProductbyId = async (token, id, msisdn,token_code,offer_id) => {
    const getProductbyId = async (token_code,offer_id,identifier) => {
        
        var msisdn_data;
        var identifier_det; 
            const api=API_BASENAME+"getUserDataPlus";
        try{
            const res = await axios.post(api,{"identifier":identifier,"status": "multiple"});              
            const products = await res.data['tokens'];    
            identifier_det = res.data['profile'];      
            //product_offers=products;
            var product_token_code_det = [];
            for(var e=0; e<products.length; e++){
                if(products[e].status === "offers_allocated"){
                    product_token_code_det.push(products[e]) ;
                }                
            }        
            product_offers=product_token_code_det;             
            // dispatch({type: "MY_API_DATA", payload: product_token_code_det})
            }catch(error){
                dispatch({type: "API_ERROR"})
            }


        dispatch({type : "SET_LOADING_BYID"})
        try{
            //product_offers = localStorage.getItem("offer_details");
            //product_offers = JSON.parse(product_offers);
            
            var product_token_code_det = [];
            var product_offers_det = [];
            for(var i=0; i<product_offers.length; i++){
                if(product_offers[i].code === token_code){
                    product_token_code_det=product_offers[i] ;
                }                
            }
            for(var j=0; j<product_token_code_det["offers"].length;j++){
                if(product_token_code_det["offers"][j].id == offer_id){
                product_offers_det=product_token_code_det["offers"][j];
                }
            }
            // dispatch({type: "MY_API_DATA_BYID", payload: product_offers[token].offers[id]})
            dispatch({type: "MY_API_DATA_BYID", payload: product_offers_det})
        }catch(error){
        dispatch({type: "API_ERROR_BYID"})
        }
        return identifier_det;
    };

    
    // Redeem Offer

    const redeemOffer = async (redeemurl,token, offerid,identifier, msisdn_phone,documentId,pageType,action) => {
        
        
        dispatch({type : "SET_LOADING_REEDEMBYID"})
        try{
            var msisdn_data = msisdn_phone;
            var doc_id = documentId; 
            const res = await axios.post(redeemurl,{"token":token,"msisdn":msisdn_data,"offerId":offerid,"action": "accept","channel":"WEB"});
            
            const redeemed_products = await res.data['offers'];
            
            var product_offers_detail = [];
            for(var i=0; i<redeemed_products.length; i++){
                if(redeemed_products[i].status === "delivered"){
                    product_offers_detail.push(redeemed_products[i]) ;
                }               
            }
            if(product_offers_detail.length != 0){
                product_offers_detail.push({'phoneNumber':msisdn_data});
                product_offers_detail.push({'doc_id':doc_id});
                
                dispatch({ type: "MY_API_DATA_REEDEMBYID", payload: product_offers_detail });
                navigate("/redeemsuccess?identifier="+identifier+"&pageType="+pageType+"&action="+action);
                }else{
                    for(var i=0; i<redeemed_products.length; i++){
                        if(redeemed_products[i].status === "purchased_failed"){
                            product_offers_detail.push(redeemed_products[i]) ;
                        }               
                    }   
                // product_offers_detail.push({'status':'failed'});
                product_offers_detail.push({'phoneNumber':msisdn_data});
                product_offers_detail.push({'doc_id':doc_id});
                
                dispatch({ type: "MY_API_DATA_REEDEMBYID", payload: product_offers_detail });
                navigate("/redeemfailed?identifier="+identifier+"&pageType="+pageType);
                }   
            
        
        }catch(error){
        // dispatch({type: "API_ERROR_REEDEMBYID"})
        // console.log(error);
        // navigate("/?identifier="+identifier);
        }
    };

            
    return <AppContext.Provider value={{...state, getProductbyId, getProducts, getProductshistory, redeemOffer}}>{children}</AppContext.Provider>;
}

//hooks

const prodcontext = () => {
    return useContext(AppContext);
}


export {AppProvider, AppContext, prodcontext};
