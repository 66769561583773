import React from 'react';
import { Routes, Route} from "react-router-dom";
import './App.css';
import ItemDetails from './ItemDetails';
import Recharge from './RechargeOnline';
import History from './AwardHistory';
import RedeemSuccess from './RedeemSuccess';
import Nooffer from './Nooffer';
import RedeemFailed from './RedeemFailed';
import ComingSoon from './ComingSoon';
import NoActiveOffer from './NoActiveOffer';
import Christmas from './Christmas';
import Valentine from './Valentine';

const App = () => {
  return (
    <>
    
        <Routes>
          
          <Route exact path="/" element={<Recharge />} />
          <Route exact path="/rechargeonline" element={<Recharge />} />
          <Route exact path="/awardhistory" element={<History />} />
          {/* <Route exact path="/itemdetails/:token/:id" element={<ItemDetails />} /> */}
          <Route exact path="/itemdetails" element={<ItemDetails />} />
          <Route exact path="/redeemsuccess" element={<RedeemSuccess />} />
          <Route exact path="/redeemfailed" element={<RedeemFailed />} />
          <Route exact path="/nooffer" element={<Nooffer />} />
          <Route exact path="/noactiveoffer" element={<NoActiveOffer />} />
          <Route exact path="/comingsoon" element={<ComingSoon />} />
          <Route exact path="/christmas" element={<Christmas />} />
          <Route exact path="/valentine" element={<Valentine />} />
        </Routes>
             
    
    </>
  );
}

export default App;
