import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
// import { useParams } from 'react-router-dom';
import { prodcontext } from './context/Maincontext';
import '../css/fonts.css';
import '../css/main.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/singleitem.css';
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import CryptoJS from "crypto-js";
import tealiumCollect from 'tealium-collect';
import { API_BASENAME } from './Constant';


const ItemDetails = () => {
    
    const {getProductbyId, isLoadingbyId ,msisdn_data, productbyId, redeemOffer, msisdn_phone, documentId, profile_rygplustype} = prodcontext();
    
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const msisdn = queryParameters.get("msisdn");
    const token_code = queryParameters.get("token");
    const offer_id = queryParameters.get("offer");
    const offer_name = queryParameters.get("offerName");
    const prizeType = queryParameters.get("prizeType");
    var identifier=queryParameters.get("identifier");
    identifier=identifier.replaceAll(" ","+");
    const game = queryParameters.get("game");

    var Tealium = require('tealium');
        var tealiumCollect = require('tealium-collect');
        var config = {};		
        // var tealium = Tealium(config);
        var tealium = Tealium();
        tealium.addModule(tealiumCollect);
        // Code for encryption -->
		// Keys for AES encryption-->
        var INITIALIZATION_VECTOR = "710d805acf0d25086d9181a03e773484"; 
        var KEY = "e5a885172b758539489a077fe6f8df60";
        var clave = CryptoJS.enc.Hex.parse(KEY); 
        var vector  = CryptoJS.enc.Hex.parse(INITIALIZATION_VECTOR); 
        const [msisdnMain, setmsisdnMain] = useState();
        const [documentIdMain, setDoccumentId] = useState();

    useEffect (() =>{
        // trackEventOnLoad(msisdn_phone,documentId);
        window.scroll(0, 0);
        // getProductbyId(token_code,offer_id,identifier);
        (async () => {
            var resp=await getProductbyId(token_code,offer_id,identifier);
            var url = new URL(window.location.href).toString();
        var platform;
    
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            platform = 'movilizado';
        } else {
            platform = 'web';
        }  
        var pageReferrer = document.referrer;
            if(resp){
                setmsisdnMain(resp['msisdn']);
                setDoccumentId(resp['documentId']);
            trackEventOnLoad(resp['msisdn'], resp['documentId'],offer_name,platform,pageReferrer,url, prizeType)
          }
          })();

    },[]);     
    
    if (isLoadingbyId != false) {
        return <div>Loading...</div>;
      }

    function redeem(){
    trackEventClick(offer_id,offer_name,msisdnMain,documentIdMain, profile_rygplustype, "loQuiero", prizeType);
    // const url= "https://stg-rygplus.lumata.com/server_restapi/redeem";
    const url= API_BASENAME+"redeemPlus";
    // const url= "https://stg-osp-rest.lumata.com/server_restapi/redeem";
    redeemOffer(url,token_code,offer_id,identifier, msisdnMain, documentIdMain, "detailsPage",game);
    }

    function tohome(){
      navigate("/rechargeonline?identifier="+identifier);
  }
    // tealium integration part


        
                
        function enc1 (data) {         
            var encrypted = CryptoJS.AES.encrypt(data, clave, {iv: vector}); 
            return encrypted.toString().trim(); 
        }				

        var url = new URL(window.location.href).toString();
        var platform;
    
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            platform = 'movilizado';
        } else {
            platform = 'web';
        }    
        var pageReferrer = document.referrer;

        function trackEventOnLoad(msisdn_phone, documentId, offername,platform,pageReferrer,url, prizeType) {
			
            var phoneNumber = msisdn_phone;
            var documentId = documentId;
            
            var profile_type;
            // if(profile_rygplustype=="no_online"){
            //   profile_type="premioOffline";
            // }
            // else{
            //   profile_type="premioOnline";
            // }
            function camelCase(str) {
              return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                  return index == 0 ? word.toLowerCase() : word.toUpperCase();
              }).replace(/\s+/g, '');
          }
          var offerNamecc= camelCase(offername);
          var pageEnvironment =`${process.env.REACT_APP_PAGEENV}`;
            var utag_data={
                "platform"			:	platform,
                "brand"				:	"orange",
                "pageName"			:	platform+":orange:recargaGana:seleccionarPremio:detallePremio:"+offerNamecc,
                "pageURL"			:	url,
                "pageReferrer"		:	pageReferrer,
                "pageCategory"		:	"recargaGana",
                "pageSubCategory"	:	"seleccionarPremio:detallePremio:"+offerNamecc,
                "pageType"			:	"seccion",
                "pageError"			:	"",
                "pageEnvironment"	:	pageEnvironment,
                "pageLanguage"		:	"es",
                "journeyName"		:	"recargaGana:seleccionPremio:" + prizeType,
              "customerLine"		:	phoneNumber, 
              "customerDocument"	:	documentId,
                "eventCategory"		:	"",
                "eventAction"		:	"",
                "eventLabel"		:	""};
              // tealium.track("view", utag_data);
            //   console.log(utag_data);
              window.utag.track("view", utag_data);
              }


        function trackEventClick(offerid,offername,msisdn_phone, documentId, profile_rygplustype, action, prizeType) {
			
            var phoneNumber = msisdn_phone;
            var documentId = documentId;
            var profile_type;
            // if(profile_rygplustype=="no_online"){
            //   profile_type="premioOffline";
            // }
            // else{
            //   profile_type="premioOnline";
            // }
            function camelCase(str) {
              return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                  return index == 0 ? word.toLowerCase() : word.toUpperCase();
              }).replace(/\s+/g, '');
          }
          var offerNamecc= camelCase(offername);
          var pageEnvironment =`${process.env.REACT_APP_PAGEENV}`;
            var utag_data={
              "platform"			:	platform,
              "brand"				:	"orange",
              "pageName"			:	platform+":oranges:recargaGana:seleccionarPremio:detallePremio:"+offerNamecc,
              "pageURL"			    :	url,
              "pageReferrer"		:	pageReferrer,
              "pageCategory"		:	"recargaGana",
              "pageSubCategory"	    :	"seleccionarPremio:detallePremio:"+offerNamecc,
              "pageType"			:	"seccion",
              "pageError"			:	"",
              "pageEnvironment"	    :	pageEnvironment,
              "pageLanguage"		:	"es",
              "journeyName"		    :	"recargaGana:seleccionPremio:" + prizeType,
              "customerLine"		:	phoneNumber, 
              "customerDocument"	:	documentId,
              "eventCategory"		:	"recargaGana:seleccionarPremio:detallePremio",
              "eventAction"		    :	action,
              "eventLabel"		    :	offerNamecc};
  
            //   console.log(utag_data);
              window.utag.track("link", utag_data);
            }


    return (
        <><br/>{productbyId != null && productbyId['id'] == offer_id ? 
            
            <div className='container max-div'>            
                <div className="col-md-12 row">
                    <Breadcrumbs aria-label="breadcrumb">
                    <NavLink to={`/?identifier=${identifier}`}> Home </NavLink>
                    <NavLink to={`/?identifier=${identifier}`}> Recarga & Gana </NavLink> 
                    </Breadcrumbs>
                    <div className="col-md-8 div_scroll"><br/>
                        <div className='item_img'>
                        <img className={game === "wheel" ? "img_prop_wheel" : "img_prop"} src={productbyId[['url']]} alt={productbyId.company}/>
                        </div><br/>

                        <div className='item_heading'>
                        <h2 className="subtitle1"><p className='cardText'>{productbyId['name']}</p></h2>  
                        </div><br/>

                        <div className='item_description '>
                        <p className="smallfont">
                        {productbyId['longdescription'].split('|').map((line, index) => (
							<React.Fragment key={index}>
							{line}
							<br /><br />
							</React.Fragment>
						))}
                        </p>
                        </div><br/>
                        {/* <div className='item_img'>
                        <img className="img_prop" src={productbyId[['url2']]} alt={productbyId.company}/>
                        </div> */}
                    </div>
                    <div className={`col-md-4 max-div-size ${game === "wheel" ? "boton" : ""}`}>
                        <div className='item_btn_redeem_div btn_div'>                                              
                        <center>
                        
                        {game === "wheel" ? (
							<button className='item_btn_redeem body_font1' onClick={redeem}>Quiero Jugar</button>
						) : (
							<button className='item_btn_redeem body_font1' onClick={redeem}>Lo Quiero</button>
						)}     
                     
                       </center>
                        </div><br/>
                        <div className='item_btn_redeem_div btn_div2'><center>
                        <button className='item_btn_home body_font1' onClick={() => tohome ()}>Volver</button></center>
                        </div>
                    </div>
                    <div className="col-md-12"><br/> </div> 
                </div>            
            </div>
            :<div>Loading...</div>}
        </>
    )
}

export default ItemDetails
