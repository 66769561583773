import React, { useEffect, useState } from 'react'
import '../css/fonts.css';
import '../css/main.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/singleitem.css';
import ImageGridV from './ImageGridV';


const Valentine = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const x = parseInt(queryParameters.get("v"));
    
    const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768); // Cambia 768 según tus necesidades
		};

		// Configurar el escucha del evento de cambio de tamaño de la ventana
		window.addEventListener('resize', handleResize);
	
		// Llamar a handleResize cuando el componente se monte para establecer el estado inicial
		handleResize();
	
		// Eliminar el oyente del evento cuando el componente se desmonte
		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []); // La dependencia vacía asegura que este efecto solo se ejecute una vez al montar el componente

    return (
        <>
            <br/>
            <div className='container'>            
                <div className="col-md-12 row">
                    <div className="col-md-12">
                    
                        {!isMobile && <div style={{ 
							  backgroundImage: "url(/image/valentine/h1.png)", 
							  backgroundSize: "100%", 
							  paddingBottom: "6%",
							  paddingTop:"5%"
							}}> 
							<span  style={{color: "white", fontSize: "1.2rem", marginLeft: "3%", fontWeight: "500", lineHeight:"1"}}>Este mes del amor te traemos el juego</span>
							<br/>
							<span style={{color: "white", fontSize: "2.8rem", marginLeft: "3%", fontWeight: "500", lineHeight:"1"}}>Encuentra la pareja perfecta</span>
						</div>} 
						
						{isMobile && <div style={{ 
							  backgroundImage: "url(/image/valentine/h2.png)", 
							  backgroundSize: "100%", 
							  paddingBottom: "18%",
							  paddingTop:"18%"
							}}> 
							<span style={{color: "white", fontSize: "0.8rem", marginLeft: "2%", fontWeight: "500", lineHeight:"1"}}>Este mes del amor te traemos el juego</span>
							<br/>
							<span style={{color: "white", fontSize: "1.5rem", marginLeft: "2%", fontWeight: "500", lineHeight:"1", textOverflow: "clip", whiteSpace: "nowrap", overflow: "hidden"}}> Encuentra la pareja perfecta</span>
						</div>}
						
						<br/>
                        {/*
                        <div className='item_img'>
                        <img className="img_prop" src="./image/x.png" alt="calendario"/>
                        </div><br/>
                        */}
                        
                        <div>
      					<ImageGridV linkImageNumber={x} />
    					</div>
    					<br/>                        
                        
                    </div>                    
                </div>                
                <br/>
                
            </div>
            
        </>
    )
}

export default Valentine
