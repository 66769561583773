import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const gifGallery = [
	{
		src: './image/valentine/1.png',
		value: 1,
	},
	{
		src: './image/valentine/2.png',
		value: 2,
	},
	{
		src: './image/valentine/3.png',
		value: 3,
	},
	{
		src: './image/valentine/4.png',
		value: 4,
	},
	{
		src: './image/valentine/5.png',
		value: 5,
	},
	{
		src: './image/valentine/6.png',
		value: 6,
	},
	{
		src: './image/valentine/7.png',
		value: 7,
	},
	{
		src: './image/valentine/p.png',
		value: 8,
	},
	{
		src: './image/valentine/p.png',
		value: 9,
	}
	];

const shuffle = (array) => {
	return array.sort(() => Math.random() - 0.5);
};

const newList = shuffle(gifGallery);

const ImageGridV = ({ linkImageNumber }) => {
	const queryParameters = new URLSearchParams(window.location.search);
	const identifier = queryParameters.get('identifier');
	const navigate = useNavigate();
	const [buttonEnabled, setButtonEnabled] = useState(false);
	
	const tohome = () => {
	navigate('/rechargeonline?identifier=' + identifier);
	};
	
	// Crear un estado para guardar el estilo de las imágenes
	const [imageStyle, setImageStyle] = useState (newList.map (() => ({ filter: 'brightness(0%)' })));
	
	// Crear un estado para guardar el valor de prevClickValue
	const [prevClickValue, setPrevClickValue] = useState(null);
	
	const [openCells, setOpenCells] = useState(0);
	
	const [oneOK, setOneOK] = useState(null);
	
	var handleClick = (value, index) => {
		//console.log('clicked');
		//console.log('prevClickValue ', prevClickValue);
		//console.log('value ', value);
		//console.log('openCells', openCells);
		//console.log('buttonEnabled', buttonEnabled);
		//console.log('oneOK', oneOK);
		
		if (!buttonEnabled) {
			// Cambiar el estilo de la imagen clickeada
			let newStyle = [...imageStyle];
			// Obtener el estilo actual de la imagen
			let currentStyle = imageStyle[index];
			// Crear un nuevo estilo con el valor de filter invertido
			let newFilter = { filter: currentStyle.filter === "none" ? "brightness(0%)" : "none" };
			// Reemplazar el estilo de la imagen con el nuevo
			if (!(openCells == 2 && newFilter.filter == "none")  && value != oneOK) {
				if (prevClickValue == null) {
					// Si no hay ninguna imagen seleccionada previamente, actualizar el estado con el valor actual
					setPrevClickValue(value);
					if (value == 8 || value == 9){
						setOneOK(value);
					}
				} else if ((oneOK == 8 && value == 9) || (oneOK == 9 && value == 8)) {
					// Si hay una coincidencia con las imágenes especiales, activar el botón de premio
					//console.log('entrando else');
					setButtonEnabled(prevState => true);
					//console.log(buttonEnabled);
				} else if (value == 8 || value == 9){
					// Si no hay una coincidencia, reiniciar el estado con el valor actual
					setOneOK(value);
					setPrevClickValue(value);
				} else {
					setPrevClickValue(value);
				}
				
				if (newFilter.filter == "none") {
					setOpenCells(openCells + 1);
				} else {
					setOpenCells(openCells - 1);
				}
				if (openCells == 0) {
					setOneOK(value);
				}							
				newStyle.splice(index, 1, newFilter);
				setImageStyle(newStyle);
			} else if (value == oneOK) {
				setOneOK(null);
				setOpenCells(openCells - 1);
				newStyle.splice(index, 1, newFilter);
				setImageStyle(newStyle);
			} else if (openCells == 2 && newFilter.filter == "none") {
				let newStyle2 = newList.map(() => ({ filter: "brightness(0%)" }));
				// Actualizar el estado imageStyle con el nuevo array
				if (value == 8 || value == 9){
					setOneOK(value);
				} else {
					setOneOK(null);
				} 
				setPrevClickValue(value);
				setOpenCells(openCells - 1);
				newStyle2.splice(index, 1, newFilter);
				setImageStyle(newStyle2);				
			}		
		}
		
	};

	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768); // Cambia 768 según tus necesidades
		};

		// Configurar el escucha del evento de cambio de tamaño de la ventana
		window.addEventListener('resize', handleResize);
	
		// Llamar a handleResize cuando el componente se monte para establecer el estado inicial
		handleResize();
	
		// Eliminar el oyente del evento cuando el componente se desmonte
		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []); // La dependencia vacía asegura que este efecto solo se ejecute una vez al montar el componente

	return (
	<div className='container'>
		<div className='col-md-12 row align-items-center'>
			<div className='col-md-6 image-gridV'>
				{newList.map((image, index) => {
					let imgValue = newList[index].value;
					let imgSrc = newList[index].src;
	
					return (
						<div key={imgValue} className={'grid-itemV link-item'}>
							<button className="image-button" style={{border: "none"}} onClick={() => handleClick(imgValue, index)}>
								<img className="img-prop1" src={imgSrc} alt={imgValue} style= {imageStyle[index]} />
							</button>
						</div>
					);
				})}
	
				{isMobile && <br />}
	
			</div>
			<div className="col-md-6 ">
				
				{!buttonEnabled && !isMobile && <div className='item_description'>
	        				<p style={{color: "black", fontSize: "2rem", fontWeight: "700", lineHeight:"1"}}>Instrucciones del juego</p>
	                        <p style={{color: "black", fontSize: "1.1rem", fontWeight: "500", lineHeight:"1"}}>Levanta las imágenes de dos en dos y encuentra la unica pareja que hace match y descubrirás el premio que te ha tocado.</p>
	                        <p style={{color: "black", fontSize: "1.1rem", fontWeight: "500", lineHeight:"1"}}>Cada semana ¡un premio diferente!</p>
	                        <p style={{color: "black", fontSize: "1.1rem", fontWeight: "500", lineHeight:"1"}}>¿Listo?, no esperes más, empieza a jugar y ¡Haz match!</p>
	                        <p style={{color: "black", fontSize: "1.1rem", fontWeight: "500", lineHeight:"1"}}>Recuerda, solo hay una pareja de cartas ganadora. Maximo un premio a la semana más el premio especial el día de San Valentín.</p>
                </div>}
                
                {!buttonEnabled && isMobile && <div className='item_description'>
	        				<p style={{color: "black", fontSize: "1.2rem", fontWeight: "700", lineHeight:"1"}}>Instrucciones del juego</p>
	                        <p style={{color: "black", fontSize: "1rem", fontWeight: "500", lineHeight:"1"}}>Levanta las imágenes de dos en dos y encuentra la unica pareja que hace match y descubrirás el premio que te ha tocado.</p>
	                        <p style={{color: "black", fontSize: "1rem", fontWeight: "500", lineHeight:"1"}}>Cada semana ¡un premio diferente!</p>
	                        <p style={{color: "black", fontSize: "1rem", fontWeight: "500", lineHeight:"1"}}>¿Listo?, no esperes más, empieza a jugar y ¡Haz match!</p>
	                        <p style={{color: "black", fontSize: "1rem", fontWeight: "500", lineHeight:"1"}}>Recuerda, solo hay una pareja de cartas ganadora. Maximo un premio a la semana más el premio especial el día de San Valentín.</p>
                </div>}
						
				<center>
					{buttonEnabled && !isMobile && <div className='item_description'>
	        				<p style={{color: "black", fontSize: "2rem", fontWeight: "700", lineHeight:"1"}}>¡¡Has hecho match!!</p>
	                        <p style={{color: "black", fontSize: "1.1rem", fontWeight: "500", lineHeight:"1"}}>¿Quieres saber que premio te ha tocado?</p>	
	                        <p style={{color: "black", fontSize: "1.1rem", fontWeight: "500", lineHeight:"1"}}>Pincha en el enlace para descubrirlo y aceptarlo</p>                        
                	</div>}
                
                	{buttonEnabled && isMobile && <div className='item_description'>
	        				<p style={{color: "black", fontSize: "1.2rem", fontWeight: "700", lineHeight:"1"}}>¡¡Has hecho match!!</p>
	                        <p style={{color: "black", fontSize: "1rem", fontWeight: "500", lineHeight:"1"}}>¿Quieres saber que premio te ha tocado?</p>
	                        <p style={{color: "black", fontSize: "1rem", fontWeight: "500", lineHeight:"1"}}>Pincha en el enlace para descubrirlo y aceptarlo</p>	                        
                	</div>}
					{buttonEnabled && <div className='item_btn_redeem_div btn_div'><button className='item_btn_redeem body_font1' onClick={tohome}>Descubre tu premio</button></div>}
				</center>
			</div>	
		</div>
	</div>
	
	);
};

export default ImageGridV;