import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ImageGrid = ({ linkImageNumber }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const identifier = queryParameters.get("identifier");
  const navigate = useNavigate();
  const images1 = [
    './image/xmas/x1.png',
    './image/xmas/x2.png',
    './image/xmas/x3.png',
    './image/xmas/x4.png',
    './image/xmas/x5.png',
    './image/xmas/x6.png',
    './image/xmas/x7.png',
    './image/xmas/x8.png',
    './image/xmas/x9.png',
    './image/xmas/x10.png',
    './image/xmas/x11.png',
    './image/xmas/x12.png',
    './image/xmas/x13.png',
    './image/xmas/x14.png',
    './image/xmas/x15.png',
    './image/xmas/x16.png',
    './image/xmas/x17.png',
    './image/xmas/x18.png',
    './image/xmas/x19.png',
    './image/xmas/x20.png',
    './image/xmas/x21.png',
    './image/xmas/x22.png',
    './image/xmas/x23.png',
    './image/xmas/x24.png'
  ];
  
  const images2 = [
    './image/xmas/x1_ok.png',
    './image/xmas/x2_ok.png',
    './image/xmas/x3_ok.png',
    './image/xmas/x4_ok.png',
    './image/xmas/x5_ok.png',
    './image/xmas/x6_ok.png',
    './image/xmas/x7_ok.png',
    './image/xmas/x8_ok.png',
    './image/xmas/x9_ok.png',
    './image/xmas/x10_ok.png',
    './image/xmas/x11_ok.png',
    './image/xmas/x12_ok.png',
    './image/xmas/x13_ok.png',
    './image/xmas/x14_ok.png',
    './image/xmas/x15_ok.png',
    './image/xmas/x16_ok.png',
    './image/xmas/x17_ok.png',
    './image/xmas/x18_ok.png',
    './image/xmas/x19_ok.png',
    './image/xmas/x20_ok.png',
    './image/xmas/x21_ok.png',
    './image/xmas/x22_ok.png',
    './image/xmas/x23_ok.png',
    './image/xmas/x24_ok.png'
  ];
  
  const images3 = [
    './image/xmas/x1_ko.png',
    './image/xmas/x2_ko.png',
    './image/xmas/x3_ko.png',
    './image/xmas/x4_ko.png',
    './image/xmas/x5_ko.png',
    './image/xmas/x6_ko.png',
    './image/xmas/x7_ko.png',
    './image/xmas/x8_ko.png',
    './image/xmas/x9_ko.png',
    './image/xmas/x10_ko.png',
    './image/xmas/x11_ko.png',
    './image/xmas/x12_ko.png',
    './image/xmas/x13_ko.png',
    './image/xmas/x14_ko.png',
    './image/xmas/x15_ko.png',
    './image/xmas/x16_ko.png',
    './image/xmas/x17_ko.png',
    './image/xmas/x18_ko.png',
    './image/xmas/x19_ko.png',
    './image/xmas/x20_ko.png',
    './image/xmas/x21_ko.png',
    './image/xmas/x22_ko.png',
    './image/xmas/x23_ko.png',
    './image/xmas/x24_ko.png'
  ];
  
  const tohome = () => {
    navigate("/rechargeonline?identifier="+identifier);
  };
  
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Cambia 768 según tus necesidades
    };

    // Configurar el escucha del evento de cambio de tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Llamar a handleResize cuando el componente se monte para establecer el estado inicial
    handleResize();
    
  // Eliminar el oyente del evento cuando el componente se desmonte
  return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // La dependencia vacía asegura que este efecto solo se ejecute una vez al montar el componente

  return (
    
    <div className='image-grid'>
    {images1.map((image, index) => {
      let imgSource = images1[index];

      if (index + 1 === linkImageNumber) {
        imgSource = images2[index];
      } else if (index + 1 < linkImageNumber) {
        imgSource = images3[index];
      }

      return (
        <div key={index} className={index + 1 === linkImageNumber ? 'grid-item link-item' : 'grid-item'}>
          {index + 1 === linkImageNumber ? (
            <button className="image-button" onClick={tohome}>
              <img className="img-prop1" src={imgSource} alt={`Image ${index + 1}`} />
            </button>
          ) : (
            <img className="img-prop1" src={imgSource} alt={`Image ${index + 1}`} />
          )}
        </div>
      );
    })}
    
    {isMobile && <br />}
    {isMobile && <br />}
    {isMobile && <br />}
    
  	</div>
    
    
  );
};

export default ImageGrid;
