import React, {useEffect, useState} from 'react';
// import { useParams } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../css/fonts.css';
import '../css/main.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {  prodcontext } from './context/Maincontext';
import { NavLink } from "react-router-dom";
// import Modal from './modal/Modal';
// import image1 from '../src/assets/image';
import moment from "moment";
import CryptoJS from "crypto-js";
import tealiumCollect from 'tealium-collect';
import { API_BASENAME } from './Constant';
// import Tealium from 'tealium-collect';
import ScrollToTopOnMount from './ScrollToTopOnMount';


const Recharge = () => {

// const [showModal, setShowModal] = useState(true);
const {getProducts, isLoading, products, redeemOffer, msisdn_phone, documentId, profile_rygplustype, productsofflinedata}= prodcontext();
// const closeModal = () => setShowModal(false);
// const text= "Helllo Modal Content pass as props";
var queryParameters = new URLSearchParams(window.location.search);
var identifier=queryParameters.get("identifier");
// var pageName=queryParameters.get("pageName");
// if(pageName == null || pageName == undefined){
//   pageName = "rootPage";
// }
if(identifier !="" || identifier != null || identifier != undefined){
identifier=identifier.replaceAll(" ","+");
}
var msisdn = msisdn_phone;
// Code for encryption -->
		// Keys for AES encryption-->
    var Tealium = require('tealium');
    var tealiumCollect = require('tealium-collect');
    var config = {};		
    // var tealium = Tealium(config);
    var tealium = Tealium();
    tealium.addModule(tealiumCollect);
		var INITIALIZATION_VECTOR = "710d805acf0d25086d9181a03e773484"; 
		var KEY = "e5a885172b758539489a077fe6f8df60";
		var clave = CryptoJS.enc.Hex.parse(KEY); 
		var vector  = CryptoJS.enc.Hex.parse(INITIALIZATION_VECTOR); 
    const [msisdnMain, setmsisdnMain] = useState();
    const [documentIdMain, setDoccumentId] = useState();

function redeem(tokenCode,offerId,offername,msisdn_phone,documentId, action, prizeType){
  trackEventClick(offerId,offername,msisdnMain,documentIdMain, action, prizeType);
  const url= API_BASENAME+"redeemPlus";
  redeemOffer(url,tokenCode,offerId,identifier,msisdn_phone,documentId,"rootPage",action);
}


useEffect (() =>{
  
  (async () => {
    const API = API_BASENAME+"getUserDataPlus";
    var resp=await getProducts(API, identifier);
    if(resp){
      setmsisdnMain(resp['msisdn']);
      setDoccumentId(resp['documentId']);
    trackEventOnLoad(resp['msisdn'], resp['documentId']);
    }
  })();
},[]);
  if (isLoading) {    
    return <div>Loading...</div>;
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1
    }
  };

var renderSlides = products.map((token, idx) => (
token.offers.map((offer, idy) => (
<div key={`auc_${idy}`}>
	<div className='card1'>
		<img className={offer.game === "wheel" ? "product--image--wheel" : "product--image"} src={offer.url} alt={offer.name}/>
		<br/><br/>
		<h2 className="standard cardText">{offer.name}</h2><br />
		<p className='cardText maxline font-color'>{offer.description}</p><br />
		<NavLink to={`/itemdetails?token=${token.code}&offer=${offer.id}&offerName=${offer.name}&prizeType=${offer.prizeType}&identifier=${identifier}&game=${offer.game}`} onClick={() => trackEventClick(offer.id,offer.name,msisdnMain,documentIdMain,"masInfo",offer.prizeType)}>
		<p className="cardText underline font-color_orange body_font4">Más información</p>
		</NavLink>
		<center>
	
		{offer.game === "wheel" ? (
			<button className='cardText body_font3 carousel_btn_redeem' onClick={() => redeem(token.code, offer.id, offer.name, msisdnMain, documentIdMain, "wheel", offer.prizeType)}>
				Quiero jugar
			</button>
		) : (
			<button className='cardText body_font3 carousel_btn_redeem' onClick={() => redeem(token.code, offer.id, offer.name, msisdnMain, documentIdMain, "loQuiero", offer.prizeType)}>
				Lo quiero
			</button>
		)}
		</center>
	
	</div>
	<br/><br/><br/>
</div>
))
));

//no-online data part

var renderSlidesOffline=[];
if(profile_rygplustype=="no_online" && productsofflinedata!=undefined){
    renderSlidesOffline = productsofflinedata.map((offer, idx) => (
    <div key={`auc_${idx}`}>
      <div className='card1'>                
        <img className="product--image" src={offer.imageurl} alt={offer.title}/><br/><br/>
        <div className='card2'>
        <h2 className="standard cardText">{offer.title}</h2><br/>
        <p className='cardText maxline font-color'>{offer.description}</p><br/>
        
        <u><p className="cardText underline font-color body_font4">Más información</p></u>
        
        <center><button className='cardText body_font3 carousel_btn_offline nopoint' >Lo quiero</button></center>
        </div>
      </div>
    <br/><br/>
    </div>
));
}
else{
  renderSlidesOffline=[];
}
// tealium integration part


			  
		// function enc1 (data) {           
		// 	var encrypted = CryptoJS.AES.encrypt(data, clave, {iv: vector}); 
		// 	return encrypted.toString().trim(); 
		// }				
		// End code for encryption -->
		
    var url = new URL(window.location.href).toString();
    var platform;

            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                platform = 'movilizado';
            } else {
                platform = 'web';
            }
            
    var pageReferrer = document.referrer;
      function trackEventOnLoad(msisdn_phone,documentId) {
        var phoneNumber = msisdn_phone;
        var documentId = documentId;
        var profile_type;
        
        // Tealium Universal Data Object 
        var pageEnvironment =`${process.env.REACT_APP_PAGEENV}`;
          var utag_data={
            "platform"			:	platform,
            "brand"				:	"orange",
            "pageName"			:	platform+":orange:recargaGana:seleccionarPremio",
            "pageURL"			:	url,
            "pageReferrer"		:	pageReferrer,
            "pageCategory"		:	"recargaGana",
            "pageSubCategory"	:	"seleccionarPremio",
            "pageType"			:	"seccion",
            "pageError"			:	"",
            "pageEnvironment"	:	pageEnvironment,
            "pageLanguage"		:	"es",
            "journeyName"		:	"",
            "customerLine"		:	phoneNumber,
            "customerDocument"	:	documentId,
            "eventCategory"		:	"",
            "eventAction"		:	"",
            "eventLabel"		:	""};
          // tealium.track("view", utag_data);
          // console.log(utag_data);
          window.utag.track("view", utag_data);
          }


        function trackEventClick(offerid,offername,msisdn_phone,documentId, action, prizeType) {
          var phoneNumber = msisdn_phone;
          var documentId = documentId;
          var profile_type;
          function camelCase(str) {
            return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                return index == 0 ? word.toLowerCase() : word.toUpperCase();
            }).replace(/\s+/g, '');
        }
        var offerNamecc= camelCase(offername);
        var pageEnvironment =`${process.env.REACT_APP_PAGEENV}`;
          var utag_data={
            "platform"			    :	platform,
            "brand"				      :	"orange",
            "pageName"			    :	platform+":orange:recargaGana:seleccionarPremio",
            "pageURL"			      :	url,
            "pageReferrer"		  :	pageReferrer,
            "pageCategory"		  :	"recargaGana",
            "pageSubCategory"	  :	"seleccionarPremio",
            "pageType"			    :	"seccion",
            "pageError"			    :	"",
            "pageEnvironment"	  :	pageEnvironment,
            "pageLanguage"		  :	"es",
            "journeyName"		    :	"recargaGana:seleccionPremio:" + prizeType,
            "customerLine"		  :	phoneNumber, 
            "customerDocument"	:	documentId,
            "eventCategory"		  :	"recargaGana:seleccionarPremio",
            "eventAction"		    :	action,
            "eventLabel"		    :	offerNamecc};

            // console.log(utag_data);
            window.utag.track("link", utag_data);
        }


        function trackEventClick_History(msisdn_phone,documentId) {
          var phoneNumber = msisdn_phone;
          var documentId = documentId;
          var pageEnvironment =`${process.env.REACT_APP_PAGEENV}`;
          var utag_data={
            "platform"			    :	platform,
            "brand"				      :	"orange",
            "pageName"			    :	platform+":orange:recargaGana:seleccionarPremio",
            "pageURL"			      :	url,
            "pageReferrer"		  :	pageReferrer,
            "pageCategory"		  :	"recargaGana",
            "pageSubCategory"	  :	"seleccionarPremio",
            "pageType"			    :	"seccion",
            "pageError"			    :	"",
            "pageEnvironment"	  :	pageEnvironment,
            "pageLanguage"		  :	"es",
            "journeyName"		    :	"",
            "customerLine"		  :	phoneNumber, 
            "customerDocument"	:	documentId,
            "eventCategory"		  :	"recargaGana:seleccionarPremio",
            "eventAction"		    :	"acceso",
            "eventLabel"		    :	"historialPremios"};

            // console.log(utag_data);
            window.utag.track("link", utag_data);
        }
      

        // tealium endss

  return (
    <> 
    {/* {products != null || products.length > 0  ? */}
        <div className='container' >
        	
        	<ScrollToTopOnMount />
        
            <div className="col-md-12">
            {/* {showModal && <Modal closeit={closeModal} modalContent={text} />} */}
                <div className='main_carousel'><br/>       
                    <h1 className='allheads headline1'>Selecciona tu premio</h1><br/>
                    
                    {renderSlides.map(function(d, idx){
                      
                      return(
                        <>
                        <p className='allheads subtitle2'>Recarga del {moment(products[idx].sent_date).zone('+0100').format('DD/MM/yyyy')}</p><br/>
                        <Carousel   showArrows={true}
                                  autoPlay={false}
                                  infiniteLoop={false}
                                  Swipe to slide = {true}                        
                                  responsive={responsive}
                                  showDots={true}                        
                                  swipeable={true}
                                  draggable={true}
                                  // itemClass="carousel-item-padding-40-px"
                                  
                      >                    
                      {d}
                      </Carousel>
                      <br/><br/>
                      
                      
                      </>
                      )
                      })
                    }
                </div>
                { profile_rygplustype=="no_online" ?
                <div className='main_carousel'><br/>
                <h1 className='allheads headline1'>Premios por recargas online</h1><br/>
                <Carousel   showArrows={true}
                                autoPlay={false}
                                infiniteLoop={false}
                                Swipe to slide = {true}                        
                                responsive={responsive}
                                showDots={true}                        
                                swipeable={true}
                                draggable={true}
                                itemClass="carousel-item-padding-40-px"
                    >                    
                    {renderSlidesOffline}
                    </Carousel>
                    </div>:<div></div>}
                <div>
                        
                        <NavLink to={`/awardhistory?identifier=${identifier}`} onClick={()=> trackEventClick_History(msisdnMain,documentIdMain)}>
                        {/* <NavLink to={`/awardhistory?identifier=${identifier}`} onClick={()=> window.scroll(0, 0)}> */}
                        <img className="product--image--history" src="./image/banner-web-link.webp" alt="historypage" />
                        </NavLink><br/><br/>

                        {/* {trackEventOnLoad()} */}
                </div>
            </div>
        </div> 
        {/* : <div></div>} */}
    </>
  )
}

export default Recharge
