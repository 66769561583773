import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { BrowserRouter, HashRouter, MemoryRouter } from 'react-router-dom';
import { AppProvider } from './components/context/Maincontext';
import { Provider } from 'react-redux';
import reduxStore from './components/reducer/reduxStore';

const root = ReactDOM.createRoot(document.getElementById('root'));
reduxStore.subscribe(() => console.log(reduxStore.getState()));
root.render(
  // <React.StrictMode>
    
    <BrowserRouter>
    <AppProvider>
    <Provider store={reduxStore}>
    {/* <Header /> */}
    <App />
    {/* <Footer /> */}
    </Provider>
    </AppProvider>
    </BrowserRouter>
  //  </React.StrictMode>
);