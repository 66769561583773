import React, {useEffect} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../css/fonts.css';
import '../css/main.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {  prodcontext } from './context/Maincontext';
// import { NavLink } from "react-router-dom";
import moment from "moment";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import CryptoJS from "crypto-js";
import { API_BASENAME } from './Constant';

const History = () => {
  

 
const {getProductshistory, isLoadingHistory, productsHistory, profile_rygplustype}= prodcontext();
const queryParameters = new URLSearchParams(window.location.search);
const msisdn = queryParameters.get("msisdn");
var identifier=queryParameters.get("identifier");
identifier=identifier.replaceAll(" ","+");
// Code for encryption -->
		// Keys for AES encryption-->
    var Tealium = require('tealium');
    var tealiumCollect = require('tealium-collect');
    var config = {};		
    // var tealium = Tealium(config);
    var tealium = Tealium();
    tealium.addModule(tealiumCollect);
    var INITIALIZATION_VECTOR = "710d805acf0d25086d9181a03e773484"; 
    var KEY = "e5a885172b758539489a077fe6f8df60";
    var clave = CryptoJS.enc.Hex.parse(KEY); 
    var vector  = CryptoJS.enc.Hex.parse(INITIALIZATION_VECTOR); 

    
useEffect (() =>{
  window.scroll(0, 0);
  

  (async () => {
    
    const API = API_BASENAME+"getUserDataPlus";
    var resp=await getProductshistory(API, identifier);
    
    if(resp){
    trackEventOnLoad(resp['msisdn'], resp['documentId'])
  }
  })();
},[]);

// if (isLoadingHistory) {    
//   return <div>Loading...</div>;
// }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1
    }
  };

  var renderSlides = [];
  
     if(productsHistory!=null){
     renderSlides = productsHistory.map((offer, idy) => ( 
     
            <div key={`auc_${idy}`}>
                <div className='card1'>
        
                
                <img className="product--image" src={offer.url} alt={offer.name}/><br/><br/>
                
                <h2 className="standard cardText">{offer.name}</h2><br/>
                <p className='cardText maxline font-color'>Entregada el día {moment(offer.consumed_date).utc().format("DD/MM/yyyy")}</p><br/>         
                
                
                </div>
                <br/><br/><br/>
            </div>
        ))

     }

// tealium integration part


            
    // function enc1 (data) {           
    //     var encrypted = CryptoJS.AES.encrypt(data, clave, {iv: vector}); 
    //     return encrypted.toString().trim(); 
    // }				

    var url = new URL(window.location.href).toString();
    var platform;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        platform = 'movilizado';
    } else {
        platform = 'web';
    }
            
    var pageReferrer = document.referrer;

    function trackEventOnLoad(msisdn_phone,documentId) {
      var phoneNumber = msisdn_phone;
      var documentId = documentId;
      var profile_type;
      // if(profile_rygplustype=="no_online"){
      //   profile_type="premioOffline";
      // }
      // else{
      //   profile_type="premioOnline";
      // }
      var pageEnvironment =`${process.env.REACT_APP_PAGEENV}`;
      // Tealium Universal Data Object 
        var utag_data={
          "platform"			:	platform,
          "brand"				:	"orange",
          "pageName"			:	platform+":orange:recargaGana:historialPremios",
          "pageURL"			:	url,
          "pageReferrer"		:	pageReferrer,
          "pageCategory"		:	"recargaGana",
          "pageSubCategory"	:	"historialPremios",
          "pageType"			:	"seccion",
          "pageError"			:	"",
          "pageEnvironment"	:	pageEnvironment,
          "pageLanguage"		:	"es",
          "journeyName"		:	"",
          "customerLine"		:	phoneNumber,
          "customerDocument"	:	documentId,
          "eventCategory"		:	"",
          "eventAction"		:	"",
          "eventLabel"		:	""};
        // tealium.track("view", utag_data);
        // console.log(utag_data);
        window.utag.track("view", utag_data);
        }

return (
  <> <br/> {isLoadingHistory===undefined || isLoadingHistory=='undefined' || isLoadingHistory==null || isLoadingHistory ?<div>Loading...</div>:
      <div className='container' >
                    
          <div className="col-md-12">
                    <Breadcrumbs aria-label="breadcrumb">
                    <NavLink to={`/?identifier=${identifier}`}> Home </NavLink>
                    <NavLink to={`/?identifier=${identifier}`}> Recarga & Gana </NavLink> 
                    </Breadcrumbs>
          {/* {showModal && <Modal closeit={closeModal} modalContent={text} />} */}
              <div className='main_carousel'>  <br/>     
                  <h1 className='allheads headline1'>Historial de premios</h1><br/>
                      <>
                      {/* <p className='allheads subtitle2'>Recarga del {productsHistory.sent_date}</p><br/> */}
                      <Carousel   showArrows={true}
                                autoPlay={false}
                                infiniteLoop={false}
                                Swipe to slide = {true}                        
                                responsive={responsive}
                                showDots={true}                        
                                swipeable={true}
                                draggable={true}
                                // itemClass="carousel-item-padding-40-px"
                                
                    >                    
                    {renderSlides.length>0?renderSlides:[]}
                    </Carousel>
                    <br/><br/>
                    
                    
                    </>
                    
                   
              </div>
              
          </div>
      </div>}
  </>
)
}

export default History
