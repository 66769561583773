import React, { useEffect, useState } from 'react'
import '../css/fonts.css';
import '../css/main.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/singleitem.css';
import ImageGrid from './ImageGrid';


const Christmas = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const x = parseInt(queryParameters.get("x"));

    return (
        <>
            <br/>
            <div className='container'>            
                <div className="col-md-12 row">
                    <div className="col-md-12">

                        <div className='allheads subtitle2'>Calendario de adviento: Canjea un regalo cada día</div> <br/>
                        {/*
                        <div className='item_img'>
                        <img className="img_prop" src="./image/x.png" alt="calendario"/>
                        </div><br/>
                        */}
                        
                        <div>
      					<ImageGrid linkImageNumber={x} />
    					</div>
    					<br/>                        
                        
                    </div>                    
                </div>                
                <br/>
                
            </div>
            
        </>
    )
}

export default Christmas
